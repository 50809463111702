import 'moment/locale/en-gb';
import 'moment/locale/fi';
import 'moment/locale/nb';
import 'moment/locale/sv';

import type { FC, ReactNode } from 'react';
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';

import moment from 'moment';

import { Country, Product, ZCountry, ZProduct } from 'src/api/zrm/zrmApi';
import i18n from 'src/i18n';
import OnlineFormType from 'src/types/OnlineFormType';
import { zCountryToCountry, zProductToProduct } from 'src/utils/zoordinates';

interface SettingsContextProps {
  country: Country;
  product: Product;
  selectedLanguage: string;
  setSelectedLanguage: Dispatch<SetStateAction<string>>;
  enableDevTools?: boolean;
  grFirst?: boolean;
  formType: OnlineFormType;
  evFirst?: boolean;
}

interface SettingsProviderProps {
  onlineFormType: OnlineFormType;
  enableDevTools?: boolean;
  children?: ReactNode;
  language?: string;
  grFirst?: boolean;
  evFirst?: boolean;
}

const SettingsContext = createContext<SettingsContextProps>({
  country: Country.SE,
  product: Product.Blanco,
  selectedLanguage: 'sv',
  setSelectedLanguage: () => { },
  enableDevTools: false,
  grFirst: false,
  formType: OnlineFormType.BlancoSweden,
  evFirst: false,
});

export const SettingsProvider: FC<SettingsProviderProps> = (props) => {
  const { onlineFormType, enableDevTools = false, children, language, grFirst, evFirst } = props;

  const [country, setCountry] = useState<Country>(Country.SE);
  const [product, setProduct] = useState<Product>(Product.Blanco);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(language);
  const [formType, setFormType] = useState<OnlineFormType>(null);

  useEffect(() => {
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
      moment.locale(selectedLanguage);
    }
  }, [selectedLanguage]);

  useEffect(() => {
    if (onlineFormType) {
      const c = onlineFormType.slice(2, 4).toUpperCase() as ZCountry;
      const p = onlineFormType.slice(0, 2).toUpperCase() as ZProduct;

      setCountry(zCountryToCountry(c));
      setProduct(zProductToProduct(p));
      setFormType(onlineFormType);
    }
  }, [onlineFormType]);

  const value = useMemo(() => ({
    country,
    product,
    selectedLanguage,
    setSelectedLanguage,
    enableDevTools,
    grFirst,
    formType,
    evFirst,
  }), [country, product, selectedLanguage, enableDevTools, formType, grFirst, evFirst]);

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;

export const useSettingsContext = () => useContext(SettingsContext);
