import { FC, useEffect } from 'react';

import { Container } from '@mui/material';

import FormEntryCard from 'src/components/FormEntry/FormEntryCard';
import { useAuthContext } from 'src/contexts/auth/AuthContext';
import { MarketingConsentContextProvider } from 'src/contexts/consents/MarketingConsentContext';
import { useFormStepperDialogContext } from 'src/contexts/FormStepperDialogContext';

import EvFirstAppCreatedPage from './EvFirstAppCreatedPage';
import EvFirstProperties from './EvFirstProperties';
import EvFirstStartPage from './EvFirstStartPage';

interface EvFirstProps { }

const EvFirst: FC<EvFirstProps> = (props) => {
  const { } = props;
  const { checkBankIdNoAuth, authState, authFinished } = useAuthContext();
  const { appIsCreated } = useFormStepperDialogContext();

  useEffect(() => {
    checkBankIdNoAuth();
  }, []);

  if (!authFinished) {
    return <></>;
  }

  return (
    <>
      {authState?.authenticated && (
        <>
          {appIsCreated && (
            <EvFirstAppCreatedPage />
          )}
          {!appIsCreated && (
            <MarketingConsentContextProvider>
              <EvFirstProperties />
            </MarketingConsentContextProvider>
          )}
        </>
      )}
      {!authState?.authenticated && (
        <Container maxWidth='sm'>
          <FormEntryCard>
            <EvFirstStartPage />
          </FormEntryCard>
        </Container>
      )}
    </>
  );
};


export default EvFirst;
