import { StrictMode } from 'react';

import ReactDOM from 'react-dom/client';

import App from './App';
import { AnalyticsContextProvider } from './contexts/AnalyticsContext';
import { APIProvider } from './contexts/APIContext';
import { AuthContextProvider } from './contexts/auth/AuthContext';
import { CustomerDataContextProvider } from './contexts/auth/CustomerDataContext';
import { LoginContextProvider } from './contexts/auth/LoginContext';
import { SettingsProvider } from './contexts/SettingsContext';
import ThemeControlContextProvider from './contexts/ThemeControlContext';
import { ValidatorContextProvider } from './contexts/ValidatorContext';
import { YupSchemaContextProvider } from './contexts/YupSchemaContext';
import OnlineFormType from './types/OnlineFormType';


import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';

const ROOT_ELEMENT_ID = process.env.REACT_APP_ROOT_ELEMENT_ID || 'online-forms';

const root = ReactDOM.createRoot(
  document.getElementById(ROOT_ELEMENT_ID)
);

const rootElement = document.getElementById(ROOT_ELEMENT_ID);
const formType = rootElement?.getAttribute('data-formType') || OnlineFormType.BlancoSweden;
const language = rootElement?.getAttribute('data-language') || 'en';
const enableDevTools = rootElement?.getAttribute('data-devTools') === 'true';
const grFirst = rootElement?.getAttribute('data-grFirst') === 'true';
const evFirst = rootElement?.getAttribute('data-evFirst') === 'true';



root.render(
  <StrictMode>
    <AuthContextProvider>
      <APIProvider>
        <SettingsProvider
          onlineFormType={formType as OnlineFormType}
          enableDevTools={enableDevTools}
          language={language}
          grFirst={grFirst}
          evFirst={evFirst}
        >
          <CustomerDataContextProvider>
            <AnalyticsContextProvider>
              <ValidatorContextProvider>
                <YupSchemaContextProvider>
                  <ThemeControlContextProvider>
                    <LoginContextProvider>
                      <App onlineFormType={formType as OnlineFormType} />
                    </LoginContextProvider>
                  </ThemeControlContextProvider>
                </YupSchemaContextProvider>
              </ValidatorContextProvider>
            </AnalyticsContextProvider>
          </CustomerDataContextProvider>
        </SettingsProvider>
      </APIProvider>
    </AuthContextProvider>
  </StrictMode>
);
