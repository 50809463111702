import { FC, useMemo } from 'react';

import { Box, Card, SxProps, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { BalconyType, Country, PartialCondominium, PartialHouse, PartialVacationHome } from 'src/api/zrm';
import formatCurrency from 'src/utils/format/formatCurrency';
import formatPercent from 'src/utils/format/formatPercent';
import isEmpty from 'src/utils/lodashLike/isEmpty';


import CondominiumIcon from './icons/CondominiumIcon';
import HouseIcon from './icons/HouseIcon';
import VacationHouseIcon from './icons/VacationHomeIcon';


// Union type with all fields
type PartialProperty = (PartialHouse | PartialCondominium | PartialVacationHome) & {
  property_type: 'HOUSE' | 'CONDOMINIUM' | 'VACATION_HOME';
  apartment_number?: string;
  elevator?: boolean | null;
  organization_number?: string | null;
  organization_name?: string | null;
  floor?: number | null;
  balcony_type?: BalconyType | null;
  rooms?: number | null;
  cadastral_designation?: string | null;
};



interface EvPropertyDesktopTableProps {
  properties: (PartialHouse | PartialCondominium | PartialVacationHome)[];
}

const EvPropertyDesktopTable: FC<EvPropertyDesktopTableProps> = (props) => {
  const { properties } = props;

  const { t } = useTranslation();
  const theme = useTheme();


  const getTableRenderer: (isHouse: boolean) => Array<{
    title: string;
    dataIndex: string;
    renderer: (row: PartialProperty) => string | number;
    hidden?: boolean;
    sx?: SxProps;
  }> = useMemo(() => (isHouse: boolean) => {
    return [
      {
        title: t('Street address'),
        dataIndex: 'street_address',
        renderer: (row: PartialProperty) => (row.street_address),
      },
      {
        title: t('Apartment number'),
        dataIndex: 'apartment_Number',
        renderer: (row: PartialProperty) => (row.apartment_number),
        hidden: isHouse,
      },
      {
        title: t('Living area m2'),
        dataIndex: 'square_meter_living_area',
        renderer: (row: PartialProperty) => (row.square_meter_living_area),
      },
      {
        title: t('Last known common debt'),
        dataIndex: 'last_known_common_debt',
        renderer: (row: PartialProperty) => (row.ev_partial?.last_known_common_debt),
      },
      {
        title: t('Net value estimate'),
        dataIndex: 'net_value_estimate',
        renderer: (row: PartialProperty) => (formatCurrency(row.ev_partial?.net_value_estimate, Country.NO, 0)),
      },
    ].filter(x => !x.hidden);
  }, [t, formatCurrency, formatPercent, theme.palette.primary.main]);


  const propertyHousesRenderer = getTableRenderer(true);
  const propertyCondominiumsRenderer = getTableRenderer(false);

  const propertyHouses: PartialHouse[] = properties.filter((property: PartialProperty): property is PartialHouse => property.property_type === 'HOUSE');
  const propertyCondominiums: PartialCondominium[] = properties.filter((property): property is PartialCondominium => property.property_type === 'CONDOMINIUM');
  const propertyVacationHomes: PartialVacationHome[] = properties.filter((property): property is PartialVacationHome => property.property_type === 'VACATION_HOME');

  const propertyHousesSum = propertyHouses.reduce((acc, curr) => ({
    net_value_estimate: acc.net_value_estimate + curr.ev_partial?.net_value_estimate,
  }), {
    net_value_estimate: 0,
  }
  ) as Record<string, number>;

  const propertyCondominiumsSum = propertyCondominiums.reduce((acc, curr) => ({
    net_value_estimate: acc.net_value_estimate + curr.ev_partial?.net_value_estimate,
  }), {
    net_value_estimate: 0,
  }
  ) as Record<string, number>;

  const propertyVacationHomesSum = propertyVacationHomes.reduce((acc, curr) => ({
    net_value_estimate: acc.net_value_estimate + curr.ev_partial?.net_value_estimate,
  }), {
    net_value_estimate: 0,
  }
  ) as Record<string, number>;

  return (
    <>
      {!!propertyCondominiums.length && (
        <Card  sx={{ mb: 5 }}>
          <Box sx={{ pl: 1, pt: 1, backgroundColor: '#e8f4ec', display: 'flex', alignItems: 'left' }}>
            <CondominiumIcon />
            <Typography sx={{ fontSize: '1rem', mb: 1, pl: 2, pb: 2, pt: 2 }}>
              {t('Condominiums')}
            </Typography>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                {propertyCondominiumsRenderer.map((column) => (
                  <TableCell
                    key={`ev-table2-header-${column.dataIndex}`}
                  >
                    {column.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {propertyCondominiums?.map((property: PartialCondominium) => (
                <TableRow
                  key={`ev-table2-${property.external_id}`}
                >
                  {propertyCondominiumsRenderer.map((column) => (
                    <TableCell
                      key={`ev-table2-col-${property.external_id}-${column.dataIndex}`}
                      sx={{ ...column.sx }}
                    >
                      {column.renderer(property) || '-'} 
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              <TableRow>
                {propertyCondominiumsRenderer.map((column) => (
                  <TableCell
                    key={`ev-table2-col-summary-${column.dataIndex}`}
                    sx={{ fontWeight: 'bold' }}
                  >
                    {!isEmpty(propertyCondominiumsSum[column.dataIndex]) ? formatCurrency(propertyCondominiumsSum[column.dataIndex], Country.NO, 0) : ''}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      )}
      {!!propertyHouses.length && (
        <Card sx={{ mb: 5 }}>
          <Box sx={{ pl: 1, pt: 1, backgroundColor: '#e8f4ec', display: 'flex', alignItems: 'left' }}>
            <HouseIcon />
            <Typography sx={{ fontSize: '1rem', mb: 1, pl: 2, pb: 2, pt: 2 }}>
              {t('Houses')}
            </Typography>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                {propertyHousesRenderer.map((column) => (
                  <TableCell
                    key={`ev-table-header-${column.dataIndex}`}
                  >
                    {column.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {propertyHouses?.map((property: PartialHouse) => (
                <TableRow
                  key={`ev-table-${property.external_id}`}
                >
                  {propertyHousesRenderer.map((column) => (
                    <TableCell
                      key={`ev-table-col-${property.external_id}-${column.dataIndex}`}
                      sx={{ ...column.sx }}
                    >
                      {column.renderer(property) || '-'}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              <TableRow>
                {propertyHousesRenderer.map((column) => (
                  <TableCell
                    key={`ev-table-col-summary-${column.dataIndex}`}
                    sx={{ fontWeight: 'bold' }}
                  >
                    {!isEmpty(propertyHousesSum[column.dataIndex]) ? formatCurrency(propertyHousesSum[column.dataIndex], Country.NO, 0) : ''}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      )}
      {!!propertyVacationHomes.length && (
        <Card sx={{ mb: 5 }}>
          <Box sx={{ pl: 1, pt: 1, backgroundColor: '#e8f4ec', display: 'flex', alignItems: 'left' }}>
            <VacationHouseIcon />
            <Typography sx={{ fontSize: '1rem', mb: 1, pl: 2, pb: 2, pt: 2 }}>
              {t('Vacation Houses')}
            </Typography>
          </Box>
          <Table >
            <TableHead>
              <TableRow>
                {propertyHousesRenderer.map((column) => (
                  <TableCell
                    key={`ev-table-header-${column.dataIndex}`}
                  >
                    {column.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {propertyVacationHomes?.map((property: PartialVacationHome) => (
                <TableRow
                  key={`ev-table-${property.external_id}`}
                >
                  {propertyHousesRenderer.map((column) => (
                    <TableCell
                      key={`ev-table-col-${property.external_id}-${column.dataIndex}`}
                      sx={{ ...column.sx }}
                    >
                      {column.renderer(property) || '-'}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              <TableRow>
                {propertyHousesRenderer.map((column) => (
                  <TableCell
                    key={`ev-table-col-summary-${column.dataIndex}`}
                    sx={{ fontWeight: 'bold' }}
                  >
                    {!isEmpty(propertyVacationHomesSum[column.dataIndex]) ? formatCurrency(propertyVacationHomesSum[column.dataIndex], Country.NO, 0) : ''}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      )}
    </>
  );
};


export default EvPropertyDesktopTable;
