import { FC, useState } from 'react';

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Card, Collapse, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Country, PartialCondominium, PartialHouse, PartialVacationHome } from 'src/api/zrm';
import formatCurrency from 'src/utils/format/formatCurrency';

import EvPropertyBoxMobileRow from './EvPropertyBoxMobileRow';
import CondominiumIcon from './icons/CondominiumIcon';
import HouseIcon from './icons/HouseIcon';
import VacationHouseIcon from './icons/VacationHomeIcon';

interface EvPropertyBoxMobileProps {
  property: PartialHouse | PartialCondominium | PartialVacationHome;
}


const EvPropertyBoxMobile: FC<EvPropertyBoxMobileProps> = (props) => {
  const { property } = props;

  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);

  return (
    <Card>
      <Grid
        container
        sx={{ backgroundColor: '#e8f4ec', alignItems: 'center' }}
      >
        <Grid
          item
          xs={2}
          sx={{ display: 'flex', justifyContent: 'left',  p: 1 }}
        >
          {property.property_type === 'HOUSE' && (
            <HouseIcon />
          )}
          {property.property_type === 'CONDOMINIUM' && (
            <CondominiumIcon />
          )}
          {property.property_type === 'VACATION_HOME' && (
            <VacationHouseIcon />
          )}
        </Grid>
        <Grid
          item
          sx={{ display: 'flex', justifyContent: 'left',  alignItems: 'left', p: 1 }}
          xs={10}
        >
          <Grid
            container
            direction={'column'}
          >
            <Grid item>
              <Typography variant='h6'>
                {property.street_address}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='subtitle2'>
                {property.post_code} {property.city}
              </Typography>
            </Grid>
          
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ p: 1 }}
      >
        {property.property_type === 'CONDOMINIUM' && (
          <EvPropertyBoxMobileRow
            title={t('Apartment number')}
            value={property.apartment_number || '-'}
          />
        )}
        <EvPropertyBoxMobileRow
          title={t('Estimated value')}
          value={formatCurrency(property.ev_partial?.net_value_estimate, Country.NO, 0)}
        />
        <EvPropertyBoxMobileRow
          title={t('Monthly cost')}
          value={formatCurrency(property.monthly_cost, Country.NO, 0)}
        />
        <Collapse
          in={expanded}
          timeout="auto"
          unmountOnExit
          sx={{ width: '100%' }}
        >
          <EvPropertyBoxMobileRow
            title={t('Square meter living area')}
            value={property.square_meter_living_area || '-'}
          />
          {property.ev_partial && (
            <EvPropertyBoxMobileRow
              title={t('Last known common debt')}
              value={formatCurrency(property.ev_partial.last_known_common_debt, Country.NO, 0)}
            />
          )}
        </Collapse>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer', px: 2, py: 1, '& .MuiSvgIcon-root, & .MuiTypography-root': { fontWeight: 'bold' } }}
          onClick={() => setExpanded(p => !p)}
        >
          <Typography>
            {expanded ? t('Show less') : t('Show more')}
          </Typography>
          {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </Grid>
      </Grid>
    </Card >
  );
};


export default EvPropertyBoxMobile;
