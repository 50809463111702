import { FC, useEffect, useMemo, useState } from 'react';

import { Box, Button, CircularProgress, Container, Grid, Link, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import { ApplicantNO, Country, PartialCondominium, PartialHouse, PartialVacationHome } from 'src/api/zrm';
import FlexCenterBox from 'src/components/boxes/FlexCenterBox';
import MarketingConsentBox from 'src/components/Consents/MarketingConsentBox';
import EvPropertyDesktopTable from 'src/components/eiendomsverdi/EvPropertyDesktopTable';
import EvPropertyMobileList from 'src/components/eiendomsverdi/EvPropertyMobileList';
import { useApi } from 'src/contexts/APIContext';
import { useCustomerDataContext } from 'src/contexts/auth/CustomerDataContext';
import { BlancoApiFormContextProvider } from 'src/contexts/blanco/BlancoApiFormContext';
import { BlancoDataFormContextProvider } from 'src/contexts/blanco/BlancoDataFormContext';
import { useFormStepperDialogContext } from 'src/contexts/FormStepperDialogContext';
import BlancoNorwayFormDefaultData from 'src/defaults/BlancoNorway/BlancoNorwayFormDefaultData';
import BlancoNorwayFormStepperDialog from 'src/forms/BlancoNorway/BlancoNorwayFormStepperDialog';
import useIsMobile from 'src/hooks/mediaQuery/useIsMobile';
import BlancoNorwayForm from 'src/types/BlancoNorway/form/BlancoNorwayForm';
import formatCurrency from 'src/utils/format/formatCurrency';

interface EvFirstPropertiesProps { }

const EvFirstProperties: FC<EvFirstPropertiesProps> = (props) => {
  const { } = props;

  const { t } = useTranslation();
  const { api } = useApi();
  const isMobile = useIsMobile();
  const { customerData, setCustomerData } = useCustomerDataContext();

  const [properties, setProperties] = useState<(PartialHouse | PartialCondominium | PartialVacationHome)[]>([]);
  const [loading, setLoading] = useState(false);
  const { openDialog } = useFormStepperDialogContext();



  const fetchEVData = useMemo(() => async (requestId: string) => {
    setLoading(true);

    try {
      const resp = await api.myPages.evStatesWithConsentMyPagesEiendomsverdieWithConsentPost(
        { cancelToken: requestId, headers: { 'X-Request-ID': requestId } },
      );

      setProperties((resp.data) || []);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }, [api]);

  const fetchCustomerData = useMemo(() => async (requestId: string) => {
    setLoading(true);

    try {
      const resp = await api.myPages.getApplicantMyPagesApplicantMeGet(
        { cancelToken: requestId, headers: { 'X-Request-ID': requestId } },
      );

      setCustomerData(resp.data);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }, [api]);

  const totalAmount = useMemo(() => properties?.reduce((prev, curr) => prev + curr.ev_partial?.net_value_estimate, 0) || 0, [properties]);

  const defaultData: BlancoNorwayForm = {
    ...BlancoNorwayFormDefaultData,
    startPage: {
      ...BlancoNorwayFormDefaultData.startPage,
      loanAmount: +totalAmount.toFixed(0),
      refinanceAmount: +totalAmount.toFixed(0),
      refinance: true,
    },
    pni: {
      pni: (customerData as ApplicantNO)?.pni,
    },
    extraData: {
      isGrFirst: true,
    },
  };


  useEffect(() => {
    const requestId = v4();

    fetchEVData(requestId);
    fetchCustomerData(requestId);

    return () => { api.abortRequest(requestId); };
  }, [fetchEVData, fetchCustomerData]);

  if (loading) {
    return (
      <>
        <FlexCenterBox sx={{ mb: 2 }}>
          <Typography sx={{ fontStyle: 'italic' }}>
            {t('Please wait until your data is loaded...')}
          </Typography>
        </FlexCenterBox>
        <FlexCenterBox>
          <CircularProgress
            color="success"
            size={60}
          />
        </FlexCenterBox>
      </>
    );
  }
  // #f8f6f4

  return (
    <>
      <Container
        maxWidth='xl'
        sx={{
          backgroundColor: ' #f8f6f4', 
          borderBottom: '2px solid lightgrey',
          borderLeft: '1px solid lightgrey',
          borderRight: '1px solid lightgrey',
          borderTop: '1px solid lightgrey',
        }}
      >
        {!!properties.length && (
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              sx={{ '& .MuiTypography-root': { fontWeight: 'bold' }, mt: 5 }}
            >
              <Typography sx={{ fontSize: '2rem', mb: 1 }}>
                {t('My Property Value')}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '1.25rem' }}>
                  {t('Total Value')}
                </Typography>
                <Typography sx={{ fontSize: '1.25rem' }}>
                  {formatCurrency(totalAmount, Country.NO, 0)}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography>
                  {t('Number of Properties')}
                </Typography>
                <Typography>
                  {properties?.length || 0}
                  {t(' st')}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ mt: 2 }}
            >
              <FlexCenterBox>
                <Button
                  variant='contained'
                  sx={{ fontWeight: 'bold', width: isMobile ? '100%' : '400px' }}
                  onClick={(() => openDialog({}))}
                  //TODO: Add onClick to Mortgage form
                >
                  {t('Compare your mortgage rate')}
                </Button>
              </FlexCenterBox>
            </Grid>
            <Grid
              item
              xs={12}
            >
              {isMobile && (
                <EvPropertyMobileList properties={properties} />
              )}
              {!isMobile && (
                <EvPropertyDesktopTable properties={properties} />
              )}
            </Grid>
            <Grid
              item
              xs={12}
            >
              <FlexCenterBox  sx={{ flexDirection: 'column' }}>
                <Typography sx={{ fontSize: 15, fontWeight: 'bold', mb: 1 }}>
                  {t('Are you paying too much for your mortgage?')}
                </Typography>
                <Button
                  variant='contained'
                  sx={{ fontWeight: 'bold', width: isMobile ? '100%' : '400px' }}
                  onClick={(() => openDialog({}))}
                  //TODO: Add onClick to Mortgage form
                >
                  {t('Challenge your interest rate – compare')}
                </Button>
              </FlexCenterBox>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <FlexCenterBox  sx={{ flexDirection: 'column' }}>
                <Typography sx={{ fontSize: 15, fontWeight: 'bold', mb: 1, align: 'center' }}>
                  {t('Convert expensive unsecured loans into a mortgage?')}
                </Typography>
                <Button
                  variant='outlined'
                  color='primary'
                  sx={{ fontWeight: 'bold', width: isMobile ? '100%' : '400px' }}
                  href={window?.location?.hostname + '?grFirst=true'}
                >
                  {t('See if it is possible for you')}
                </Button>
              </FlexCenterBox>
            </Grid>
          </Grid>
        )}
        {!properties.length && (
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              sx={{ mt: 15, mb: 5 }}
              textAlign={'center'}
            >
              <Typography>{t('You don\'t have any properties.')}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <FlexCenterBox
                sx={{ flexDirection: 'column' }}
                textAlign="center"
              >
                <Typography sx={{ fontSize: 15, fontWeight: 'bold', mb: 1 }}>
                  {t('Convert expensive unsecured loans into a mortgage?')}
                </Typography>
                <Button
                  variant='contained'
                  sx={{ fontWeight: 'bold', width: isMobile ? '100%' : '400px' }}
                  href={window?.location?.hostname + '?grFirst=true'}
                >
                  {t('See if it is possible for you')}
                </Button>
              </FlexCenterBox>
            </Grid>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sx={{ mb: 2, mt: 2 }}
        >
          <Typography>
            <Trans>
              The properties information is provided by
              {' '}
              <Link
                href='https://home.eiendomsverdi.no/'
                target='_blank'
              >
                Eiendomsverdi.no
              </Link>
              .
            </Trans>
          </Typography>
        </Grid>
        <MarketingConsentBox /> 
        <BlancoApiFormContextProvider>
          <BlancoDataFormContextProvider defaultData={defaultData}>
            <BlancoNorwayFormStepperDialog />
          </BlancoDataFormContextProvider>
        </BlancoApiFormContextProvider>
      </Container>
    </>
  );
};


export default EvFirstProperties;
