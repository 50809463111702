import { FC, useEffect, useMemo, useState } from 'react';

import { Button, Grid, Link, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import FlexCenterBox from 'src/components/boxes/FlexCenterBox';
import EvConsentCheckbox from 'src/components/eiendomsverdi/EvConsentCheckbox';
import { useLoginContext } from 'src/contexts/auth/LoginContext';

interface EvFirstStartPageProps { }

const EvFirstStartPage: FC<EvFirstStartPageProps> = (props) => {
  const { } = props;

  const { t } = useTranslation();
  const { loginBankIdNo, loginError } = useLoginContext();

  const [consent, setConsent] = useState(false);
  const [consentError, setConsentError] = useState(false);

  const login = useMemo(() => () => {
    if (!consent) {
      setConsentError(true);

      return;
    }

    loginBankIdNo();
  }, [consent, loginBankIdNo]);

  useEffect(() => {
    if (consent) { setConsentError(false); }
  }, [consent]);

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Typography sx={{ fontWeight: 'bold', fontSize: '1.25rem' }}>
          {t('Consent to share property information')}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <EvConsentCheckbox
          value={consent}
          setValue={setConsent}
          error={consentError}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mb: 1 }}
      >
        <Typography sx={{ fontSize: 'small' }}>
          {/* eslint-disable react/no-unescaped-entities */}
          <Trans>
            For more details, see Zensum's
            {' '}
            <Link
              href="https://www.zensum.no/personvern"
              target="_blank"
              rel="noreferrer"
            >
              privacy policy
            </Link>.
          </Trans>
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mb: 1 }}
      >
        <FlexCenterBox>
          <Button
            variant='contained'
            onClick={login}
          >
            {t('Retrieve My Property Information')}
          </Button>
        </FlexCenterBox>
      </Grid>
      {loginError && (
        <Grid
          item
          xs={12}
          sx={{ mb: 1 }}
        >
          <FlexCenterBox>

            <Typography sx={{ fontSize: '0.75rem', color: 'error.main' }}>
              {t('There was an unexpected error logging in. Please try again.')}
            </Typography>
          </FlexCenterBox>
        </Grid>
      )}
      <Grid
        item
        xs={12}
      >
        <Typography sx={{ fontSize: 'small' }}>
          {/* eslint-disable react/no-unescaped-entities */}
          <Trans>
            By clicking "Retrieve My Property Information" you agree to our
            {' '}
            <Link
              href="https://www.zensum.no/brukervilkar"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </Link>
            {' '}
            and
            {' '}
            <Link
              href="https://www.zensum.no/personvern"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </Link>
          </Trans>
        </Typography>
      </Grid>
    </Grid>
  );
};


export default EvFirstStartPage;
